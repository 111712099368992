import { AiOutlineArrowRight } from 'react-icons/ai';
import './Header.css';
import { useEffect, useState } from 'react';
import { auth } from '../../../../config/FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

export default function HeaderAdminComponent() {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        checkIfLoggedIn();
    }, [])

    const checkIfLoggedIn = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const userEmail = user.email;
                setUser(userEmail)
            }
        });
    };

    const logout = async () => {
        await auth.signOut();
        navigate('/login');
    }

    return <div className='nav_bar'>
        <div className='frameNav'>
            <span className='logo_se77e'><img alt='' className='logo-desktop' src='../../assets/logo/SETTE-FIBRA1.svg' /></span>
            <span className='links'>
                <span className='user_title_admin'>Bem vindo, {user}</span>
                <button
                    className='buttonAssignAdmin'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={logout}
                >
                    Sair {isHovered && <AiOutlineArrowRight fontSize={17} />}
                </button>
            </span>
        </div>
    </div>
}