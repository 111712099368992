import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import 'firebase/functions';
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAQfxNylzNvyJ5Ic0GpPpwRrWm1ppW2pRQ",
  authDomain: "se77e-marketing.firebaseapp.com",
  projectId: "se77e-marketing",
  storageBucket: "se77e-marketing.appspot.com",
  messagingSenderId: "475292294545",
  appId: "1:475292294545:web:6874d06f132e2002c04e30"
});

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const stg = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const functions = getFunctions(firebaseApp);
