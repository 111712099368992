import { useEffect, useState } from 'react';
import { auth } from '../../../config/FirebaseConfig';
import './Login.css';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import Loader from '../../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const checkIfLoggedIn = () => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    navigate('/admin');
                }
            });
        };
        checkIfLoggedIn()
    }, [navigate])

    const login = async () => {
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            if(user){
                setLoading(false);
                navigate('/admin');
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Falha no login',
                text: 'Usuário ou Senha incorretos!',
              });
            console.error('Erro no login:', error.message);
        }
    };

    return <div className="login_page">
        {isLoading && <Loader />}
        <div className="container">
            <div className="row">
                <div className="col col-md-4 col-12 offset-md-4">
                    <div className="card shadow">
                        <div className='logo_login'><img alt='' className='logo-desktop' src='../../assets/logo/SETTE-FIBRA1.svg' /></div>
                        <div className="card-title">Se77e Telecom</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col col-md-12 col-12">
                                    <div className="form-group">
                                        <label className="label_login">E-mail:</label>
                                        <input
                                            type="text"
                                            id="email"
                                            className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col col-md-12 col-12">
                                    <div className="form-group mt-3">
                                        <label className="label_login">Senha:</label>
                                        <input
                                            type="password"
                                            id="senha"
                                            className="form-control"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col col-md-12 col-12">
                                    <div className="form-group">
                                        <button className="btn btn_laranja mt-3" onClick={login}>Entrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}