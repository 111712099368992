import HeaderContComponent from "../../../components/headerContactComponent/HeaderContComponent";
import { Button, Carousel, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import './HomeEditStyle.css';
import { auth, db, stg } from "../../../config/FirebaseConfig";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import FooterComponent from "../../../components/footerComponent/FooterComponent";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import Swal from "sweetalert2";
import Loader from "../../../components/loader/Loader";
import { useNavigate } from 'react-router-dom';
import HeaderAdminComponent from "../components/HeaderAdminComponent/HeaderAdminComponent";
import { onAuthStateChanged } from "firebase/auth";

export default function HomePageEdit() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [panel1, setPanel1] = useState([]);
  const [panel2, setPanel2] = useState([]);
  const [panel3, setPanel3] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const [show, setShow] = useState(false);
  const [imagensSelecionadas, setImagensSelecionadas] = useState([{ photo: null, panel: "1" }, { photo: null, panel: "2" }, { photo: null, panel: "3" }]);
  const [imagensSelecionadasMobile, setImagensSelecionadasMobile] = useState([{ photoMobile: null, panel: "1" }, { photoMobile: null, panel: "2" }, { photoMobile: null, panel: "3" }]);
  const [isLoading, setLoading] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    setLoading(true);
    const checkIfLoggedIn = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
        } else {
          console.log("não logado")
          navigate('/login');
        }
      });
    };
    checkIfLoggedIn();
    const getPanels = async () => {
      const data = await getDocs(collection(db, "panels"));
      setPanel1(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter(panel => panel.panel === 1))
      setPanel2(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter(panel => panel.panel === 2))
      setPanel3(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter(panel => panel.panel === 3))
    }

    const getCarousel = async () => {
      const carouselCollection = collection(db, 'carousel');
      const data = await getDocs(carouselCollection);
      const carouselData = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setCarousel(carouselData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getPanels();
    getCarousel();
  }, [navigate]);

  const handleImageClick = (id) => {
    const fileInput = document.getElementById(id);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleImageChange = (event, panel, display) => {
    const imagem = event.target.files[0];
    if (imagem) {
      if (display === "desktop") {

        let imagensArray = [...imagensSelecionadas];
        switch (panel) {
          case 1:
            imagensArray[0] = { photo: imagem, panel: panel };
            setImagensSelecionadas(imagensArray);
            setCarousel(prevCarousel => prevCarousel.map(item => {
              if (item.panel === panel) {
                return { ...item, photo: URL.createObjectURL(imagem) };
              }
              return item;
            }));
            break;
          case 2:
            imagensArray[1] = { photo: imagem, panel: panel };
            setImagensSelecionadas(imagensArray);
            setCarousel(prevCarousel => prevCarousel.map(item => {
              if (item.panel === panel) {
                return { ...item, photo: URL.createObjectURL(imagem) };
              }
              return item;
            }));
            break;
          case 3:
            imagensArray[2] = { photo: imagem, panel: panel };
            setImagensSelecionadas(imagensArray);
            setCarousel(prevCarousel => prevCarousel.map(item => {
              if (item.panel === panel) {
                return { ...item, photo: URL.createObjectURL(imagem) };
              }
              return item;
            }));
            break;
          default:
            break;
        }
      } else if (display === "mobile") {

        let imagensArray = [...imagensSelecionadasMobile];
        switch (panel) {
          case 1:
            imagensArray[0] = { photoMobile: imagem, panel: panel };
            setImagensSelecionadasMobile(imagensArray);
            setCarousel(prevCarousel => prevCarousel.map(item => {
              if (item.panel === panel) {
                return { ...item, photoMobile: URL.createObjectURL(imagem) };
              }
              return item;
            }));
            break;
          case 2:
            imagensArray[1] = { photoMobile: imagem, panel: panel };
            setImagensSelecionadasMobile(imagensArray);
            setCarousel(prevCarousel => prevCarousel.map(item => {
              if (item.panel === panel) {
                return { ...item, photoMobile: URL.createObjectURL(imagem) };
              }
              return item;
            }));
            break;
          case 3:
            imagensArray[2] = { photoMobile: imagem, panel: panel };
            setImagensSelecionadasMobile(imagensArray);
            setCarousel(prevCarousel => prevCarousel.map(item => {
              if (item.panel === panel) {
                return { ...item, photoMobile: URL.createObjectURL(imagem) };
              }
              return item;
            }));
            break;
          default:
            break;
        }
      }
    }
  };


  const salvarCarousel = async () => {
    setLoading(true);
    const data = await getDocs(collection(db, "carousel"));
    const listaCarousel = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

    imagensSelecionadas.forEach(element => {
      if (element.photo !== null) {
        let carousel;
        for (let c of listaCarousel) {

          if (c.panel === parseInt(element.panel)) {
            carousel = c;
          }
        }

        if (carousel.photo !== null && carousel.photo !== "" && carousel.photo.indexOf("/assets/no-image.png",) === -1) {
          const fileRef = ref(stg, carousel.photo);

          deleteObject(fileRef)
            .then(() => {
            })
            .catch((error) => {
              console.error('Erro ao deletar o arquivo:', error);
            });
        }


        const imageRef = ref(stg, `${element.photo.name + carousel.id}`);
        uploadBytes(imageRef, element.photo).then((resultado) => {
          getDownloadURL(resultado.ref).then((url) => {
            let carouselObjToUpload = {
              panel: carousel.panel,
              photo: url,
              insertDate: new Date()
            }
            const carouselCollection = collection(db, 'carousel');

            setDoc(doc(carouselCollection, carousel.id), carouselObjToUpload, { merge: true })
              .then(async function (resultado) {

              })
              .catch(function (error) {
                console.error('Erro ao atualizar documento do carousel:', error);
              });
          }).catch((error) => {
            console.error('Erro ao obter a URL:', error);
          });
        }).catch((error) => {
          console.error('Erro no upload:', error);
        });
      }
    });
    imagensSelecionadasMobile.forEach(element => {
      if (element.photoMobile !== null) {
        let carousel;
        for (let c of listaCarousel) {

          if (c.panel === parseInt(element.panel)) {
            carousel = c;
          }
        }

        if (carousel.photoMobile !== null && carousel.photoMobile !== "" && carousel.photoMobile.indexOf("/assets/no-image-mobile.png",) === -1) {
          const fileRef = ref(stg, carousel.photoMobile);

          deleteObject(fileRef)
            .then(() => {
            })
            .catch((error) => {
              console.error('Erro ao deletar o arquivo:', error);
            });
        }


        const imageRef = ref(stg, `${element.photoMobile.name + "mobile" + carousel.id}`);
        uploadBytes(imageRef, element.photoMobile).then((resultado) => {
          getDownloadURL(resultado.ref).then((url) => {
            let carouselObjToUpload = {
              panel: carousel.panel,
              photoMobile: url,
              insertDate: new Date()
            }
            const carouselCollection = collection(db, 'carousel');

            setDoc(doc(carouselCollection, carousel.id), carouselObjToUpload, { merge: true })
              .then(async function (resultado) {

              })
              .catch(function (error) {
                console.error('Erro ao atualizar documento do carousel:', error);
              });
          }).catch((error) => {
            console.error('Erro ao obter a URL:', error);
          });
        }).catch((error) => {
          console.error('Erro no upload:', error);
        });
      }
    });
    setLoading(false);
    await Swal.fire({
      title: 'Sucesso!',
      text: 'Imagem Cadastrada/Alterada com sucesso!',
      icon: 'success',
      confirmButtonText: 'Ok',
    });
    setShow(false)
  }

  const deletarImg = async (idImage, idInput, display) => {

    const imagemElement = document.getElementById(idImage);
    if (imagemElement) {
      if (imagemElement.src !== null && imagemElement.src !== "" && imagemElement.src.indexOf("/assets/no-image.png",) === -1) {
        const resultado = await Swal.fire({
          title: 'Atenção',
          text: 'Você tem certeza de que deseja deletar a imagem (após deletar a imagem não poderá mais ser recuperada)?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar'
        });

        if (resultado.isConfirmed) {
          setLoading(true);
          const panel = idImage.split('_')[1];


          const input = document.getElementById(idInput);
          if (input) {
            input.value = '';
          }


          setCarousel(prevCarousel =>
            prevCarousel.map(item => {
              if (item.panel === parseInt(panel)) {
                if (display === "desktop")
                  return { ...item, photo: "./assets/no-image.png" };
                else if (display === "mobile")
                  return { ...item, photoMobile: "./assets/no-image-mobile.png" };
              }
              return item;
            })
          );


          const carouselItem = carousel.find(item => item.panel === parseInt(panel));
          if (display === "destop")
            if (carouselItem && carouselItem.photo) {
              const fileRef = ref(stg, carouselItem.photo);

              try {
                await deleteObject(fileRef);
                console.log('Imagem deletada do storage.');
              } catch (error) {
                console.error('Erro ao deletar a imagem do storage:', error);
              }
            }
            else if (display === "mobile")
              if (carouselItem && carouselItem.photoMobile) {
                const fileRef = ref(stg, carouselItem.photoMobile);
                try {
                  await deleteObject(fileRef);
                  console.log('Imagem deletada do storage.');
                } catch (error) {
                  console.error('Erro ao deletar a imagem do storage:', error);
                }
              }


          const carouselCollection = collection(db, 'carousel');
          const querySnapshot = await getDocs(carouselCollection);
          querySnapshot.forEach(async doc => {
            const data = doc.data();
            if (data.panel === parseInt(panel)) {
              try {
                if (display === "desktop") {
                  await setDoc(doc.ref, { photo: "./assets/no-image.png" }, { merge: true });
                  setLoading(false);
                  Swal.fire('Deletado com sucesso!');
                } else if (display === "mobile") {
                  await setDoc(doc.ref, { photoMobile: "./assets/no-image-mobile.png" }, { merge: true });
                  setLoading(false);
                  Swal.fire('Deletado com sucesso!');
                }
              } catch (error) {
                console.error('Erro ao atualizar o atributo photo na collection do Firebase:', error);
              }
            }
          });

        }
      }
    }
  };

  const editarCard = (card) => {
    console.log("card", "card_" + card.panel + card.card)
    const fileInput = document.getElementById("card_" + card.panel + "_" + card.card);
    if (fileInput) {
      fileInput.click();
    }
  }

  const handleCardChange = async (event, card) => {
    const imagem = event.target.files[0];
    if (imagem) {
      switch (card.panel) {
        case 1:
          await salvarCard(card, imagem).then(() => {
            setPanel1(prevPanel1 => prevPanel1.map(item => {
              if (item.panel === card.panel) {
                if (item.card === card.card)
                  return { ...item, photo: URL.createObjectURL(imagem) };
              }
              return item;
            }));
          });
          break;
        case 2:
          await salvarCard(card, imagem).then(() => {
            setPanel2(prevPanel2 => prevPanel2.map(item => {
              if (item.panel === card.panel) {
                if (item.card === card.card)
                  return { ...item, photo: URL.createObjectURL(imagem) };
              }
              return item;
            }));
          });
          break;
        case 3:
          await salvarCard(card, imagem).then(() => {
            setPanel3(prevPanel3 => prevPanel3.map(item => {
              if (item.panel === card.panel) {
                if (item.card === card.card)
                  return { ...item, photo: URL.createObjectURL(imagem) };
              }
              return item;
            }));
          });
          break;
        default:
          break;
      }
    }
  };

  const salvarCard = async (card, imagem) => {
    setLoading(true);
    const panelsCollection = collection(db, 'panels');
    const panel = await getDoc(doc(db, 'panels', card.id));
    if (panel.exists()) {

      if (card.photo !== null && card.photo !== "") {
        const fileRef = ref(stg, card.photo);
        console.log(card)
        deleteObject(fileRef)
          .then(() => {
          })
          .catch((error) => {
            console.error('Erro ao deletar o arquivo:', error);
          });
      }

      const imageRef = ref(stg, `${imagem.name + card.id}`);
      uploadBytes(imageRef, imagem).then((resultado) => {
        getDownloadURL(resultado.ref).then((url) => {
          let panelObjToUpload = {
            photo: url,
            insertDate: new Date()
          }

          setDoc(doc(panelsCollection, card.id), panelObjToUpload, { merge: true })
            .then(async function (resultado) {
              setLoading(false);
              await Swal.fire({
                title: 'Sucesso!',
                text: 'Imagem Cadastrada/Alterada com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
              setShow(false)
            })
            .catch(function (error) {
              console.error('Erro ao atualizar documento do panel:', error);
            });
        }).catch((error) => {
          console.error('Erro ao obter a URL:', error);
        });
      }).catch((error) => {
        console.error('Erro no upload:', error);
      });

    }
  }

  return <div>
    {isLoading && <Loader />}
    <HeaderContComponent />
    <HeaderAdminComponent />
    <Carousel className="carousel_container-edit" activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item interval={7000}>
        <img onClick={() => setShow(true)} alt='' src='./assets/adicione-imagem-banner.png' className="resposnsive-image" />
      </Carousel.Item>
    </Carousel>
    <div className="content">
      <div className="content-sub">
        <div className="plan-container">
          <div className="plan-title">PLANOS <span className="plan-highlight">EXTRAORDINÁRIOS</span> PARA VOCÊ</div>
          <div className="retangle-line"></div>
          <div className="plan-cards">
            {panel1.map((card) => {
              return <span className="plan-card" onClick={() => { editarCard(card) }}>
                <input onChange={(event) => handleCardChange(event, card)} style={{ display: 'none' }} id={`card_${card.panel}_${card.card}`} type="file" className="form-control" accept="image/*" />
                <div>
                  <img alt='' src={card.photo} className="panel-card-img-edit" />
                </div>
                <div className="btn_edita_foto" onClick={(card) => { editarCard(card) }}><MdEdit className='icon-min' /></div>
              </span>
            })}
          </div>
        </div>
        <div className="plan-container">
          <div className="plan-title">ADQUIRA <span className="plan-highlight">COMBOS</span> COMPLETOS</div>
          <div className="retangle-line"></div>
          <div className="plan-cards">
            {panel2.map((card) => {
              return <span className="plan-card" onClick={() => { editarCard(card) }}>
                <input onChange={(event) => handleCardChange(event, card)} style={{ display: 'none' }} id={`card_${card.panel}_${card.card}`} type="file" className="form-control" accept="image/*" />
                <div>
                  <img alt='' src={card.photo} className="panel-card-img-edit" />
                </div>
                <div className="btn_edita_foto" onClick={(card) => { editarCard(card) }}><MdEdit className='icon-min' /></div>
              </span>
            })}
          </div>
        </div>
        <div className="plan-container">
          <div className="plan-title">NOSSAS </div>
          <div className="plan-title"><span className="plan-highlight2">VANTAGENS</span></div>
          <div className="retangle-line2"></div>
          <div className="plan-cards">
            {panel3.map((card) => {
              return <span className="plan-card" onClick={() => { editarCard(card) }}>
                <input onChange={(event) => handleCardChange(event, card)} style={{ display: 'none' }} id={`card_${card.panel}_${card.card}`} type="file" className="form-control" accept="image/*" />
                <div>
                  <img alt='' src={card.photo} className="panel-card-img-edit" />
                </div>
                <div className="btn_edita_foto" onClick={(card) => { editarCard(card) }}><MdEdit className='icon-min' /></div>
              </span>
            })}
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
    <div className="bg-1"></div>
    <div className="bg-2"></div>
    <div className="bg-3"></div>
    <div className="bg-4"></div>
    <div className="bg-5"></div>

    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      id="main-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Alterar Imagens do Banner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && ( // Renderiza o conteúdo apenas quando o modal estiver aberto
          <div>
            <h5>Desktop</h5>
            <div className="col col-md-12 col-12">
              <div className="form-group">
                <div className="row">
                  {carousel.map(item => (
                    <div className="col-md-4 col-4" key={item.id}>
                      <input onChange={(event) => handleImageChange(event, item.panel, "desktop")} style={{ display: 'none' }} id={`foto_banner_${item.panel}`} type="file" className="form-control" accept="image/*" />
                      <button className="btn_apaga_foto" onClick={() => { deletarImg(`carouselPanel_${item.panel}`, `foto_banner_${item.panel}`, "desktop") }}><FaTrash className='icon' /></button>
                      <img
                        id={`carouselPanel_${item.panel}`}
                        alt=""
                        src={item.photo || "./assets/no-image.png"}
                        className="carregar_imagem"
                        onClick={() => handleImageClick(`foto_banner_${item.panel}`)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <h5 className="mt-5">Mobile</h5>
            <div className="col col-md-12 col-12">
              <div className="form-group">
                <div className="row">
                  {carousel.map(item => (
                    <div className="col-md-4 col-4" key={item.id}>
                      <input onChange={(event) => handleImageChange(event, item.panel, "mobile")} style={{ display: 'none' }} id={`foto_banner_mobile_${item.panel}`} type="file" className="form-control" accept="image/*" />
                      <button className="btn_apaga_foto" onClick={() => { deletarImg(`carouselPanel_${item.panel}`, `foto_banner_mobile_${item.panel}`, "mobile") }}><FaTrash className='icon' /></button>
                      <img
                        id={`carouselPanel_${item.panel}`}
                        alt=""
                        src={item.photoMobile || "./assets/no-image.png"}
                        className="carregar_imagem"
                        onClick={() => handleImageClick(`foto_banner_mobile_${item.panel}`)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={salvarCarousel}>
          Salvar Alterações
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
}