import React, { useEffect } from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, ReferenceLine, Area } from 'recharts';

export function LineChartAdmin({ areaColor="#F9B041", data, tooltipContent="Valor" }) {
    useEffect(()=>{
        // console.log(data);
    }, [data])

  return (
    <ResponsiveContainer width="100%" height="100%">
    <AreaChart data={data}
      margin={{ top: 5, right: 30, left: 0, bottom: 0 }}>
      <XAxis dataKey="month" tick={{ fill: 'white' }} axisLine={{ stroke: 'white' }} />
      <YAxis tick={{ fill: 'white' }} axisLine={{ stroke: 'white' }} />
      <CartesianGrid vertical={false} stroke="#888888" />
      <Tooltip content={tooltipContent} />
      <ReferenceLine x="Page C" stroke="green" label="Min PAGE" />
      <Area type="monotone" dataKey="amount" stroke={areaColor} fill={areaColor} />
    </AreaChart>
  </ResponsiveContainer>
  );
}